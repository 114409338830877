import React, { useEffect, useState } from "react"
import LogoWhite from "../images/logoWhite.svg"
import { onValue, ref } from "firebase/database"
import { db } from "../firebase"
import noImage from "../images/no_image_available.png"
import alert from "../static/NotificationDing.mp3"
import Moment from "react-moment"
import moment from "moment"

export default function TV() {
  const [queue, setQueue] = useState([])
  const [queueLength, setQueueLength] = useState(0)

  const sortByTimeAdded = arr => {
    arr.sort(function (a, b) {
      var keyA = new Date(a.time),
        keyB = new Date(b.time)
      // Compare the 2 dates
      if (keyA < keyB) return -1
      if (keyA > keyB) return 1
      return 0
    })

    return arr
  }

  useEffect(() => {
    onValue(ref(db), snapshot => {
      const data = snapshot.val()

      if (data) {
        const arrOfData = Object.keys(data).map(item => data[item])
        setQueue(arrOfData)
      } else {
        setQueue([])
      }
    })
  }, [])

  useEffect(() => {
    if (queue.length > queueLength) {
      try {
        if (typeof Audio != "undefined") {
          const audio = new Audio()
          audio.src = alert
          audio.play()
        }
      } catch (e) {}
    }
    setQueueLength(queue.length)
  }, [queue])

  const getQueueLength = () => queueLength

  return (
    <div className="w-full min-h-screen  bg-ascendance py-5">
      <source src={alert} type="audio/mp3" autostart="false"></source>
      <div className="mx-auto w-11/12 md:w-10/12">
        <div className="flex justify-between items-center mt-2 md:mt-0">
          <h1 className="text-2xl md:text-5xl text-white font-medium">
            Pickup Queue
          </h1>

          <img className="w-24 md:w-60 " src={LogoWhite} alt="Company Logo" />
        </div>
        <div className="grid grid-cols-2 gap-1 md:gap-4 mt-10">
          {queue.length > 0 &&
            sortByTimeAdded(queue).map(dancer => (
              <div
                className={`itemInQueue flex space-x-8 items-center bg-white py-2 px-2 text-center rounded-md shadow-sm relative ${
                  Date.now() - dancer.time < 4000 && "addedToQueue"
                }`}
                key={dancer.name}
              >
                <img
                  className="w-20 sm:w-24 lg:w-24 rounded-lg"
                  src={dancer.url === "" ? noImage : dancer.url}
                />
                <div>
                  {dancer.name.split(" ").map(namePart => (
                    <h2 className="text-gray-600 text-2xl md:text-3xl font-medium text-left">
                      {namePart}
                    </h2>
                  ))}
                  <div className="flex space-x-2 items-center justify-between relative w-full">
                    <p className="text-left text-lg text-gray-500 mt-5">
                      <Moment format="h:mm A">{dancer.time}</Moment>
                    </p>
                  </div>
                </div>
                {!dancer.bagNumber && (
                  <p className="border-2  border-ascendance rounded-md flex justify-center items-center mt-2 font-bold absolute bottom-2 right-2 p-1 text-2xl">
                    {dancer.costumes}
                  </p>
                )}
                {dancer.bagNumber && (
                  <p className="border-2  border-ascendance rounded-md flex justify-center items-center mt-2 font-bold absolute bottom-2 right-2 p-1 text-2xl">
                    {dancer.bagNumber}
                  </p>
                )}
                {/* <p className="border-2  border-ascendance rounded-md p-1 flex justify-center items-center mt-2 font-bold absolute bottom-2 right-2 text-lg text-gray-600">
                  {dancer.ageGroup}
                </p> */}
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
